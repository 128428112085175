hr.block {
  border-bottom: rem-calc(8px) solid black;
  width: 3rem;
  margin: 5px 0;

  &.wide {
    width: 10rem;
  }

  &.color {
    border-bottom: rem-calc(8px) solid $isle-green;
    margin: 15px 0;
  }
}
