//body {
//  font-size: rem-calc(14px);
//}


h1 {
  font-family: Gotham-Bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h2 {
  font-family: Gotham-Book, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

h3 {
  font-family: Gotham-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h4 {
  font-family: Gotham-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 2;
}

h5 {
  font-family: Gotham-Book, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h6 {
  font-family: Gotham-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#nav-title {
  font-family: Gotham-Book, "Helvetica Neue", Helvetica, Arial, sans-serif;
}