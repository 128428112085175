.filter-container {

  .accordion-title {
    padding: rem-calc(6px);
  }

  label:hover {
    color: $isle-green;
  }

  input {
    margin: 0 0 0.5rem;
  }
}

