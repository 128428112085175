.orbit {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.orbit-container, .orbit-wrapper, .orbit-slide {
  padding-bottom: 56.25% !important;
  height: 0;
  width: 100%;
  object-fit: contain;
}

.orbit-figure {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.orbit-image {
  max-height: 90%;
  max-width: 100%;
  object-fit: contain;
  margin: auto;
}

.thumbnail-image {
  object-fit: contain;
  height: 10em;
  width: auto;
}

.thumbnail-border {
  text-align: center;
}