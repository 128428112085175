a.subs {
  color: white !important;
  background-color: black;
  height: 5rem;
  font-size: rem-calc(28px);
  text-align: left;
  margin-bottom: 1px !important;
}

.tech_islechemy {
  color: white !important;
  background-color: #4f7082;
  font-size: small;
  margin-bottom: 1px !important;
}

div.yoursubs {
  padding-bottom: rem-calc(25px);
}

div.nosubs {
  padding-bottom: rem-calc(25px);
}