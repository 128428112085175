.tabs-nav {
  border-bottom: 1px solid $isle-green;

  .tabs-title {
    border-top: 1px solid $isle-green;
    border-right: 1px solid $isle-green;
    border-left: 1px solid $isle-green;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }

  a {
    text-align: center;
    vertical-align: middle;
  }
}