table.edit_company {
  input {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  select {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.add_user {
  input {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  select {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.users {
  a.button {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.edit_user {
  input {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  select {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.subscriptions {
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 10px;
}

h1.inline {
  display: inline-block;
}

a.adduser, a.addtech {
  float: right;
}

.profile-image {
  height: 10em;
  width: auto;
}

table.basetechnologies {
  a.button {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.edit_technology_base {
  input {
    margin-top: 10px;
  }

  select {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.edit_technology_portal {
  input {
    margin-top: 10px;
  }

  select {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.edit_technology_cat {
  input {
    margin-top: 10px;
  }

  select {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.edit_technology_bio {
  input {
    margin-top: 10px;
  }

  select {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}