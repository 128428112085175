.image {
  height: 18em;
  width: auto;
}

.figure {
  text-align: center;
}

.padding {
  padding: 0;
  padding-top: 0.5em;
}