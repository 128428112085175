.boxed {
  background-color: $light-gray;
  padding: rem-calc(10px);
  padding-top: rem-calc(15px);
  border-bottom: 1px solid white;

  &.future {
    background-color: $isle-green;
    color: #ffffff;

    &:hover {
      background-color: #008e8c;
      color: #ffffff;
    }
  }


  &.first_event {
    background-color: #008e8d;
    color: #ffffff;

    &:hover {
      background-color: #005e5c;
    }

  }

  &.other_events {
    background-color: $isle-green;
    color: #ffffff;

    &:hover {
      background-color: #005e5c;
    }

  }

}

ul.event-label {
  margin-left: 0;
  margin-bottom: rem-calc(5px);

  li {
    display: inline;
    padding: 0;
    font-size: 1rem;

    &.is-active > a {
      color: $real-menu-active-color;
    }

    &:not(:last-child)::after {
      position: relative;
      margin: 0.25rem;
      opacity: 1;
      content: "|";
    }
  }
}