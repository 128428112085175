div.events {
  background-color: $isle-green;
  .event-detail {
    padding: rem-calc(10px);
    border-bottom: 1px solid white;
    a {
      color: #ffffff;
    }
    &:hover {
      background-color: #007f7d;
    }
    h1.event-header {
      font-size: 22px;
    }
  }
  & > div.event-detail:first-child {
    background-color: #008e8d;
    &:hover {
      background-color: #007f7d;
    }
  }
}

div.events-blocks {
  padding-bottom: rem-calc(25px);
}

div.prev-events {
  background-color: #A9A9A9;
  .prev-event-detail {
    padding: rem-calc(10px);
    border-bottom: 1px solid white;
    a {
      color: #ffffff;
    }
    &:hover {
      background-color: #8c8c8c;
    }
    h1.prev-event-header {
      font-size: 22px;
    }
  }
}