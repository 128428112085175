table.consultant_base {
  a.button {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.consultant_edit_base {
  input {
    margin-top: 10px;
  }

  select {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.consultant_edit_portal {
  input {
    margin-top: 10px;
  }

  select {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.consultant_edit_cat {
  input {
    margin-top: 10px;
  }

  select {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

table.consultant_edit_bio {
  input {
    margin-top: 10px;
  }

  select {
    vertical-align: middle;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}