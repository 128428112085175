nav {
  margin-bottom: rem-calc(10px);
  border-bottom: rem-calc(1.5px) solid;

  img {
    padding-left: 15px;
  }

  h1 {
    color: $isle-green;
    font-size: rem-calc(32px);
    margin-bottom: 0;
  }

  ul {
    margin-left: 0;
    margin-bottom: rem-calc(5px);
    list-style: none;

    li {
      display: inline;
      padding: 0;
      font-size: 1rem;

      &.is-active > a {
        color: $real-menu-active-color;
      }

      &:not(:last-child)::after {
        position: relative;
        margin: 1rem;
        opacity: 1;
        content: "|";
      }
    }
  }
}

div.mobilemargin {
  padding-left: 1rem;
}

#errorbar {
  width: 100%;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}